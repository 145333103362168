import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootStore } from "../../../Libs/Redux";
import { getMenu } from "../../../Libs/Modules/Menu";
import { secondary } from "../../../Styles/theme";
import { TableList } from "./table";

import ExcelReport from '../../../Libs/Utils/excel/report';

export function DetailList() {
  const student = useSelector((state: RootStore) => state.student);
  const { scenario } = useParams();
  const menu = getMenu(scenario);
  const [num, setNum] = useState(student.sit_index);

  const getTime = (starttime: Date, endtime: Date) => {
    const diffMs = endtime.getTime() - starttime.getTime(); // หาค่าต่างหน่วยมิลลิวินาที
    return Math.ceil(diffMs / (1000 * 60)); // แปลงเป็นวินาที
  }
  //info => (in) IStudent > IReport > ISituation > IQuestion > IAnswer
  //scenario => (in) ISituation > IQuestion > IAnswer
  const [state, setState] = useState({
    id: student.sit_index,
    min: 1,
    max: student.scenario.length,
    day: new Date(student.scenario[student.sit_index - 1].time_start),
    time_start: new Date(student.scenario[student.sit_index - 1].time_start),
    time_finish: new Date(student.scenario[student.sit_index - 1].time_finish),
    time_total: getTime(new Date(student.scenario[student.sit_index - 1].time_start), new Date(student.scenario[student.sit_index - 1].time_finish)),
    score: student.scenario[student.sit_index - 1].score,
    data: student.scenario[student.sit_index - 1].question,
  });

  //--------------------------------------------------//
  //--------------------------------------------------//
  const handleSubmit = (event: any) => {
    event.preventDefault();
    var n = Number(num);
    if (n > state.max) {
      n = state.max;
    } else if (n < state.min) {
      n = state.min;
    }
    setNum(n);
    setState({
      ...state,
      id: n,
      day: new Date(student.scenario[n - 1].time_start),
      time_start: new Date(student.scenario[n - 1].time_start),
      time_finish: new Date(student.scenario[n - 1].time_finish),
      time_total: getTime(new Date(student.scenario[n - 1].time_start), new Date(student.scenario[n - 1].time_finish)),
      score: student.scenario[n - 1].score,
      data: student.scenario[n - 1].question,
    });
  };

  const onPrint = () => {
    ExcelReport.report(student.info, state, menu.label);
  };
  //--------------------------------------------------//
  //--------------------------------------------------//
  return (
    <Box id="detail" sx={{ px: 2, pb: 2 }}>
      <div className="dis-flex flex-row flex-m m-t-10">
        <Typography variant="body2" color="primary" sx={{ pr: 2 }}>
          {`ข้อมูลการทำแบบทดสอบ`}
        </Typography>
        <Typography variant="h5" color="secondary">
          {menu.label}
        </Typography>
      </div>
      <div className="dis-flex flex-row flex-m m-b-10" onSubmit={handleSubmit}>
        <Typography variant="body2" color="primary">
          {`แสดงการทดสอบครั้งที่`}
        </Typography>
        <TextField
          hiddenLabel
          type="number"
          variant="filled"
          InputProps={{
            inputProps: { min: state.min, max: state.max },
            disableUnderline: true,
            //readOnly: true,
            style: {
              backgroundColor: secondary,
              width: 66,
              height: 40,
              color: "#8E8E8E",
            },
          }}
          value={num}
          onChange={(e) => setNum(Number(e.target.value))}
          sx={{ px: 2 }}
        />
        <Typography variant="h5" color="secondary" sx={{ pr: 2 }}>
          {`of ${state.max}`}
        </Typography>
        <Button
          type="submit"
          variant="contained"
          sx={{ color: "#fff", px: 2 }}
          onClick={handleSubmit}
        >
          แสดงผล
        </Button>
        <Box sx={{ px: 2 }}>
          <Button variant="contained" onClick={() => onPrint()} /* disabled */>
            <PrintIcon sx={{ fontSize: 28 }} />
          </Button>
        </Box>  {/**/}
      </div>
      <div className="dis-flex flex-row flex-m m-t-10">
        <Typography variant="h5" noWrap>
          ครั้งที่&nbsp;&nbsp;{state.id}
        </Typography>
        {/* <Typography variant="h5" noWrap ml={3}>
          วันที่&nbsp;
          {dayjs(state.day).format("DD/MM/YYYY")}
        </Typography> */}
        <Typography variant="h5" noWrap ml={6}>
          RESULT&nbsp;{state.score}&nbsp;POINTS
        </Typography>
      </div>

      <div className="dis-flex flex-row">
        <Typography variant="h5" noWrap>
          เวลาเริ่มเล่น
        </Typography>
        <Typography variant="h5" noWrap ml={3}>
          {new Date(state.time_start).toLocaleString()}
        </Typography>
      </div>
      <div className="dis-flex flex-row">
        <Typography variant="h5" noWrap>
          เวลาสิ้นสุด
        </Typography>
        <Typography variant="h5" noWrap ml={4}>
          {new Date(state.time_finish).toLocaleString()}
        </Typography>
      </div>
      <div className="dis-flex flex-row m-b-10">
        <Typography variant="h5" noWrap>
          ระยะเวลาเล่น&nbsp;
        </Typography>
        <Typography variant="h5" noWrap ml={1}>
          {state.time_total}
          &nbsp;นาที
        </Typography>
      </div>
      <TableList data={state.data} />
    </Box>
  );
}
